@import "../../../styles/variables";

.imgFooter {
  height: 300px;
  width: 100%;
  background-size: cover;
  position: relative;

  &:after {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    background: linear-gradient(
      90deg,
      rgba(0, 0, 0, 0.5) 0%,
      rgba(196, 196, 196, 0) 100%
    );
  }

  @media (max-width: 768px) {
    height: 400px;
  }
}

.my-width {
  @media (max-width: 768px) {
    width: 100% !important;
    font-size: 26px;
  }
}

.mobilecta {
  @media (max-width: 768px) {
    text-align: center;
    margin-top: 16px;
  }
}

.white {
  color: white !important;
}

.inputEmail {
  border: 0;
  border-radius: 0;

  &::placeholder {
    font-size: 14px;
    color: $gray-super-light;
  }
}

.custom-cta {
  background: #fff;
  color: #111 !important;
  padding: 9px 24px;
}

.custom-cta:hover,
.custom-cta:active {
  background: #fff !important;
}
